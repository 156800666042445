<template>
    <div class="animated fadeIn">
        <b-card class="piplines-list">
            <b-card-header class="card-header-main">
                <b-row>
                    <b-col md="12">
                        <wit-select
                            v-model="activeBillingAccount"
                            placeholder="Select billing account"
                            :options="billingAccounts"
                            @input="onBillingAccountChange"
                            label="name"
                        ></wit-select>
                    </b-col>
                </b-row>
            </b-card-header>
            <b-card-body>
                <wit-table
                    :items="invoices"
                    :fields="columns"
                    striped
                    hide-per-page-selector
                    ref="invoicesTable"
                    :busy="busy"
                >
                    <template v-slot:cell(periodStart)="{item}">
                        {{ item.periodStart.toLocaleDateString() }}
                    </template>
                    <template v-slot:cell(periodEnd)="{item}">
                        {{ item.periodEnd.toLocaleDateString() }}
                    </template>
                    <template v-slot:cell(actions)="{item}">
                        <div class="button-row">
                            <a
                                class="invoice-resource"
                                :href="item.url"
                                target="_blank"
                                v-b-tooltip.hover
                                title="Open Invoice"
                            >
                                <i class="fa fa-external-link-square pointer-action"> </i
                            ></a>
                            <a
                                class="invoice-pdf-resource"
                                :href="item.pdf"
                                target="_blank"
                                v-b-tooltip.hover
                                title="Download Invoice"
                            >
                                <i class="fa fa-download pointer-action"> </i
                            ></a>
                        </div>
                    </template>
                </wit-table>
                <b-row align-h="center">
                    <span v-b-tooltip.hover title="Retrieve more" v-if="hasMore">
                        <i @click="retrieveMore" class="fa fa-angle-double-down pointer-action retrieve-more-action" />
                    </span>
                    <span v-b-tooltip.hover title="No more invoices" v-else>
                        <i class="fa fa-angle-double-down retrieve-more-action disabled-retrieve-more-action" />
                    </span>
                </b-row>
            </b-card-body>
        </b-card>

        <Loading :loading="$store.state.loading.processing" :text="$store.state.loading.text"></Loading>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'

import DetailsAction from '@/components/Actions/DetailsAction.vue'
import Loading from '@/components/loading.vue'
import WitTable from '@/components/WitTable.vue'

export default {
    data() {
        return {
            columns: ['periodStart', 'periodEnd', 'actions'],
            invoices: [],
            page: null,
            hasMore: true,
            busy: false,
        }
    },
    components: {
        Loading,
        DetailsAction,
        WitTable,
    },
    computed: {
        ...mapGetters({
            billingAccounts: 'billing/accounts',
        }),
        activeBillingAccount: {
            get() {
                return this.$store.state.billing.active
            },
        },
    },
    methods: {
        async retrieveMore() {
            try {
                this.busy = true
                const response = await this.axios.get(
                    `${process.env.VUE_APP_NODE_API_HOST}/billingAccount/searchInvoices`,
                    {
                        params: {
                            billingAccountId: this.activeBillingAccount.id,
                            limit: 10,
                            page: this.page,
                        },
                    }
                )

                this.invoices.push(
                    ...response.data.data.map(el => ({
                        id: el.id,
                        periodStart: new Date(el.period_start * 1000),
                        periodEnd: new Date(el.period_end * 1000),
                        url: el.hosted_invoice_url,
                        pdf: el.invoice_pdf,
                    }))
                )

                this.hasMore = response.data.metadata.hasMore
                this.page = response.data.metadata.nextPage
                this.busy = false
            } catch {
                this.busy = false
                this.invoices = []
            }
        },

        onBillingAccountChange() {
            this.invoices = []
            this.hasMore = true
            this.page = null
            this.retrieveMore()
        },
    },
    async created() {
        this.$store.commit('loading/PROCESSING', `Fetching your billing info...`)

        await this.$store.dispatch('billing/getAccounts')

        if (this.activeBillingAccount) {
            await this.retrieveMore()
        }

        this.$store.commit('loading/PROCESSED')
    },
}
</script>

<style lang="scss">
.invoice-resource:hover {
    text-decoration: none;
    color: #4b4b4c;
}
</style>
